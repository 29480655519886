.loader {
    border: 4px solid transparent;
    border-radius: 50%;
    border-top: 4px solid #005f9e;
    width: 40px;
    height: 40px;
    margin: 25vh auto;
    -webkit-animation: rotate 1.4s linear infinite; /* Safari */
    animation: rotate 1.4s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes rotate {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
