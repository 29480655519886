@import '~react-date-range/dist/styles.css'; /* main style file */
@import '~react-date-range/dist/theme/default.css'; /* theme css file */

.rdrDateDisplayWrapper {
    background-color: unset;
}

.rdrDateDisplayItem {
    border: 1px solid rgba(0, 0, 0, 0.23);
}

.rdrDateDisplayItemActive {
    border: 1px solid #009ad9;
}

@media screen and (max-width: 599px) {
    .rdrMonth {
        width: 100vw;
    }
}
