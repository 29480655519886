@import '~@trimblemaps/trimblemaps-js/trimblemaps.css';

.trimblemaps-popup-content {
    background: rgba(255, 255, 255, 0.8);
}

.mapTooltip {
    display: table;
}

.mapTooltipRow {
    display: table-row;
}

.mapTooltipCell {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
}

.mobile-sidebar {
    height: 100%;
    max-height: 320px;
    margin-top: 8px;
    overflow-y: auto;
}
